import React from 'react';
import PropTypes from 'prop-types';
import css from '../../Listing.APP_TARGET.scss';
import { FormattedMessage as Translation } from 'react-intl';
import { getLabelInfo } from 'Helpers/listing';
import TanakAppliedFilters from 'Components/Listing/components/TanakAppliedFilters.APP_TARGET';
import SortingContainer from 'Components/Listing/components/SortingContainer.APP_TARGET';

export const ListingHeader = ({
    itemsMetadata,
    categoryID,
    isFetchingItems,
    similarAdsCount,
    isStaticFilters,
    isFetchingLocation,
    isSortingOpen,
    locationDetails,
    originalTotal,
    suggestedItemsTotal,
    trackListingSelectSorting,
    trackListingApplySorting,
    visualizationType
}) => {
    if (isFetchingItems || isFetchingLocation) {
        return <p className={ css.listingHeaderTitle }><Translation id="loading" /></p>;
    }

    const {
        original_label,
        show_original_items,
        show_suggested_items,
        suggested_label
    } = itemsMetadata;

    const { itemsLabel, total, labelType } = getLabelInfo({
        totalItems: originalTotal,
        totalSuggestedAds: suggestedItemsTotal,
        similarAdsCount,
        show_original_items,
        show_suggested_items,
        original_label,
        suggested_label
    });

    return (
        <React.Fragment>
            {!!similarAdsCount && <p className={ css.similarAdsCountTitle }><Translation id={ 'matchSomeRequirements' } /><span className={ css.totalResults }> [{itemsMetadata.total_similar_ads} <Translation id={ 'results' } />]</span></p>}
            <TanakAppliedFilters
                categoryID={ categoryID }
                similarAdsCount={ similarAdsCount }
                isStaticFilters={ isStaticFilters }
                originalTotal={ originalTotal }
                removedSearchterm={ itemsMetadata.removed_term }
            />
            {<SortingContainer
                itemsMetadata={ itemsMetadata }
                renderType="modal"
                total={ total }
                labelFlag={ show_original_items || show_suggested_items }
                labelType={ labelType }
                label={ itemsLabel }
                location={ locationDetails }
                isSortingOpen={ isSortingOpen }
                visualizationType={ visualizationType }
                trackListingSelectSorting={ trackListingSelectSorting }
                trackListingApplySorting={ trackListingApplySorting }
                categoryID={ categoryID }
            />}
        </React.Fragment>
    );
};

ListingHeader.propTypes = {
    categoryID: PropTypes.string.isRequired,
    isFetchingItems: PropTypes.bool,
    isFetchingLocation: PropTypes.bool,
    isStaticFilters: PropTypes.bool,
    similarAdsCount: PropTypes.number,
    isSortingOpen: PropTypes.bool,
    locationDetails: PropTypes.object,
    originalTotal: PropTypes.number,
    suggestedItemsTotal: PropTypes.number,
    trackListingSelectSorting: PropTypes.func,
    trackListingApplySorting: PropTypes.func,
    visualizationType: PropTypes.oneOf(['grid', 'big', 'list']),
    itemsMetadata: PropTypes.object
};

ListingHeader.defaultProps = {
    spellCheckInfo: {},
    isFetchingItems: false,
    isFetchingLocation: false,
    isSortingOpen: false,
    locationDetails: {},
    trackListingSelectSorting: () => {},
    trackListingApplySorting: () => {},
    visualizationType: 'grid',
    itemsMetadata: {}
};

export default ListingHeader;
